@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-content .graph-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding: 16px;
}
.widget-content .no-data-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 900;
  font-size: 4rem;
}
.widget-content .rt-posts {
  width: 35%;
  height: 100%;
}
.widget-content .rt-posts .post-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  height: 100%;
  border: unset;
}
.widget-content .rt-posts .post-list .post {
  flex-grow: 1;
  padding: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.widget-content .rt-posts .post-list .post:last-item {
  margin-bottom: 0;
}
.widget-content .rt-posts .post-list .post .post-title {
  text-align: right;
  font-weight: 300;
  font-size: 1.1rem;
}
.widget-content .rt-posts .post-list .post .post-value {
  text-align: right;
  font-weight: 900;
  font-size: 1.6rem;
}
.widget-content .rt-posts .post-list .post .post-value .unit {
  font-weight: 300;
  font-size: 1.3rem;
}
.widget-content .rt-posts .post-list .post--selected {
  background-color: #f4f4f4;
}
.widget-content .rt-posts .post-list .post--selected .post-title {
  font-weight: 700;
}
.widget-content .graph {
  display: flex;
  align-items: center;
  margin-right: 0;
  padding: 8px;
  width: 65%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f4f4f4;
}
.widget-content .graph .line-graph {
  width: 100%;
}
/*# sourceMappingURL=src/oapps/widgets/widget-rt2012-graph.css.map */