








































































































@import '~variables'

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      margin $space-2 0
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black

.list
  padding 0
  height 100%
  border unset
  &--widget
    overflow auto
    max-height 190px
    height auto
  .item
    margin-bottom $space-1
    border 1px solid $grey
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    cursor pointer
    &:hover
      background-color darken(white, 2%)
    &--selected, &--selected:hover
      background-color black
      color white
    &:last-child
      margin-bottom 0

.widget-item
  &.item--selected
    .widget-item__description
      color $grey
  .widget-item__title
    font-weight 700
  .widget-item__description
    color $dark
    font-size $fs-body

.q-stepper
  .q-stepper-nav
    justify-content flex-end
    height 10%

.back-button
  margin-right $space-1

.visual-container
  display flex
  flex 1
  justify-content center
  align-items center
  width 100%
  img
    width 40%
