@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-content .main {
  display: flex;
  flex-basis: 80%;
  justify-content: center;
  align-items: center;
}
.widget-content .main .threshold-values {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.widget-content .main .threshold-values .value-threshold,
.widget-content .main .threshold-values .percent-threshold {
  text-align: right;
}
.widget-content .main .value,
.widget-content .main .no-data-text {
  font-weight: 900;
  font-size: 3rem;
}
.widget-content .main .value {
  display: flex;
  justify-content: flex-end;
}
.widget-content .main .value--orange-color {
  color: #f2c037;
}
.widget-content .main .value--red-color {
  color: #e91e63;
}
.widget-content .main .unit {
  margin-left: 4px;
  font-weight: 300;
}
.widget-content .main .alert-percentage-loading {
  margin: 0 -16px;
  width: 30%;
  transform: rotate(-90deg);
}
.widget-content .description {
  display: flex;
  padding: 16px;
}
.widget-content .description .live {
  display: flex;
  align-items: center;
}
.widget-content .description .live .live-dot {
  display: block;
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e91e63;
}
.widget-content .description .live .live-label {
  color: #b4b2a9;
  letter-spacing: 1.5px;
}
.widget-content .description .time {
  margin-left: auto;
  font-weight: 300;
  font-size: 1rem;
}
/*# sourceMappingURL=src/oapps/widgets/widget-threshold-alert.css.map */