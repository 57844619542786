

















































@import '~variables'

.dashboards
  min-height auto
  height 100%
  .dashboard--empty
    margin-left 250px
    padding $space-3
    width 100%
    font-size $fs-h1
