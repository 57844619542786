



























































































































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    height 70%
    .no-data-text
      display flex
      justify-content center
      align-items center
      width 100%
      height 100%
      font-weight 900
      font-size 3rem
    .data-container
      display flex
      width 100%
      align-items center
      justify-content space-around
      .current-value-container
        text-align center
        &--value
          font-weight 700
          font-size $fs-h1
      .goal-value-container
        text-align center
        &--value
          font-weight 700
          font-size $fs-h1
      .donut-container
        position relative
        width 40%
        height 100%
        .donut-percent-value
          position absolute
          bottom 20%
          left 50%
          z-index -1
          font-weight $fw-bold
          font-size $fs-big
          transform translateX(-50%)
      .donut-chart
        margin auto
        width 60%
        height 100%
