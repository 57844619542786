@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.creation-step {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 90%;
}
.creation-step .creation-step__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
}
.creation-step .creation-step__header .subtitle {
  margin-bottom: 16px;
  font-size: 1.3rem;
}
.creation-step__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.creation-step__content .space-selector {
  margin: 16px 0;
  font-size: 1.3rem;
}
.creation-step__content .space-selector >>> .vue-treeselect__control {
  border-color: #000;
}
.list {
  padding: 0;
  height: 100%;
  border: unset;
}
.list--widget {
  overflow: auto;
  max-height: 190px;
  height: auto;
}
.list .item {
  margin-bottom: 8px;
  border: 1px solid #b4b2a9;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}
.list .item:hover {
  background-color: #fafafa;
}
.list .item--selected,
.list .item--selected:hover {
  background-color: #000;
  color: #fff;
}
.list .item:last-child {
  margin-bottom: 0;
}
.widget-item.item--selected .widget-item__description {
  color: #b4b2a9;
}
.widget-item .widget-item__title {
  font-weight: 700;
}
.widget-item .widget-item__description {
  color: #464749;
  font-size: 1rem;
}
.q-stepper .q-stepper-nav {
  justify-content: flex-end;
  height: 10%;
}
.back-button {
  margin-right: 8px;
}
.visual-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.visual-container img {
  width: 40%;
}
/*# sourceMappingURL=src/app/pages/dashboard/components/widget-step.css.map */