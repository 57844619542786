



































































































































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    height 70%
    .no-data-text
      display flex
      justify-content center
      align-items center
      width 100%
      height 100%
      font-weight $fw-extra-bold
      font-size 3rem
      &--light
        font-weight $fw-light
    .line-chart
      overflow visible
      padding-top $space-1
      width 100%
      height 100%
