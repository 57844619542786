




















































































































































@import '~variables'

.grid-tile
  padding $space-1 $space-2

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    width 100%
    .data-container
      width 100%
    .values
      display flex
      justify-content space-between
      padding 0 $space-2
      width 100%
      .values-item
        display flex
        flex-direction column
      .values-item__value, .values-item__label, .values-item__threshold
        display flex
        justify-content center
      .values-item__value
        font-weight 700
        font-size $fs-h1
        &--danger
          color $negative
  .data-container
    display flex
    flex-direction column
    align-items center
    .values-container
      border-bottom $grid-border
      .grid-tile
        flex 1
        padding $space-little $space-2
        .grid-tile__value
          width 100%
          text-align center
          font-weight 700
          font-size $fs-h1
          &--danger
            color $negative
          .unit
            margin-left $space-little
            font-weight 300
            font-size $fs-h3
    .presence-label
      margin-bottom $space-1
      font-weight 300
      font-size $fs-big
    .threshold-label
      width 100%
      text-align center
  .quality-tile
    width 100%
    .grid-tile__value
      margin-top -15px
      width 100%
      text-align center
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
