





























































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    justify-content center
    align-items center
    height 100%
    .data-container
      display flex
      flex-direction column
      justify-content center
      align-items center
      width 100%
      height 100%
    .alarms-title
      margin-bottom $space-2
      font-size $fs-h2
    .alarms
      padding $space-2
      width 100%
      height 100%
      .alarms-list
        overflow-y auto
        padding-left $space-2
        width 100%
        height 90%
        .alarm
          margin $space-1 0
