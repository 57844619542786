@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.dashboard {
  overflow-y: auto;
  height: calc(100vh - 50px);
}
.dashboard .dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  background-color: #f4f4f4;
  flex-wrap: wrap;
  padding: 16px;
}
.dashboard .dashboard__header .dashboard__name-input {
  font-size: 1.3rem;
}
.dashboard .dashboard__header .dashboard__name {
  position: relative;
  font-size: 2.3rem;
}
.dashboard .dashboard__header .dashboard__name:after {
  position: absolute;
  bottom: -8px;
  width: 100%;
  content: '';
}
.dashboard .dashboard__header .dashboard-menu {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu__title {
  font-size: 1.3rem;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list {
  position: absolute;
  top: 150%;
  right: 0;
  z-index: 10;
  padding: 0;
  width: 130px;
  border-radius: 4px;
  background-color: #fff;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list .q-list {
  padding: 0;
  border: unset;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list .q-list .q-item {
  font-weight: 400;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list .q-list .q-item:hover {
  background-color: #b4b2a9;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list .q-list .q-item:after {
  left: 0;
}
.dashboard .dashboard__header .dashboard-menu .dashboard-menu-list .q-list .item-delete {
  color: #e91e63;
}
.dashboard .vue-grid-layout >>> .vue-grid-placeholder {
  border-radius: 10px;
  background-color: #000;
}
.dashboard .widget-board {
  padding: 16px;
}
.layout-item {
  z-index: 1;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.layout-item--menu-open {
  z-index: 2;
}
.description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.description .date {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
}
.description .date .date-from,
.description .date .date-to {
  flex: 1;
}
.description .date .date-from {
  text-align: right;
}
.description .date .date-to {
  white-space: nowrap;
}
.description .date .date-label {
  margin-right: 4px;
  margin-left: 8px;
  color: #b4b2a9;
  text-transform: uppercase;
}
.bloc {
  display: flex;
  gap: 5px;
}
/*# sourceMappingURL=src/app/pages/dashboard/views/dashboard.css.map */