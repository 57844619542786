@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-content .main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.widget-content .main .data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.widget-content .main .alarms-title {
  margin-bottom: 16px;
  font-size: 1.3rem;
}
.widget-content .main .alarms {
  padding: 16px;
  width: 100%;
  height: 100%;
}
.widget-content .main .alarms .alarms-list {
  overflow-y: auto;
  padding-left: 16px;
  width: 100%;
  height: 90%;
}
.widget-content .main .alarms .alarms-list .alarm {
  margin: 8px 0;
}
/*# sourceMappingURL=src/oapps/widgets/widget-utl-alarm.css.map */