











































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 3rem
    .unit
      margin-left $space-little
      font-weight 300
  .description
    display flex
    padding $space-2
    .live
      display flex
      align-items center
      .live-dot
        display block
        margin-right $space-little
        width 10px
        height @width
        border-radius 50%
        background-color $negative
      .live-label
        color $grey
        letter-spacing 1.5px
    .time
      margin-left auto
      font-weight 300
      font-size $fs-body
