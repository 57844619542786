

























































































































































@import '~variables'

.widget-template
  display flex
  flex-direction column
  width 100%
  height 100%
  border-radius $border-radius-smooth
  .widget__header
    display flex
    align-items center
    padding $space-2
    border-bottom 1px solid $light
    .widget-title
      margin 0
      letter-spacing 0.5px
      font-weight 500
      font-size $fs-h2
      line-height 1
    .widget-actions
      display flex
      align-items center
      margin-left auto
      .menu-container
        margin-left $space-1
      .widget-handle
        position relative
        margin-right -5px
        cursor pointer
        .widget-menu
          position absolute
          top 100%
          left 100%
          padding 0
          border-radius $border-radius-smooth
          background-color white
          .q-list
            padding 0
            border unset
            .q-item
              font-weight 400
              &:hover
                background-color $grey
              &:after
                left 0 // make the separator line full width
            .menu-item--delete
              color $negative
