@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.grid-tile {
  padding: 8px 16px;
}
.widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-content .main {
  display: flex;
  flex-basis: 80%;
  justify-content: center;
}
.widget-content .main .value,
.widget-content .main .no-data-text {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 900;
  font-size: 1.6rem;
}
.widget-content .main .unit {
  margin-left: 4px;
  font-weight: 300;
}
.widget-content .data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.widget-content .data-container .grid-tile__value {
  width: 100%;
  text-align: center;
}
.widget-content .data-container .live-tile,
.widget-content .data-container .value-tile {
  width: 100%;
}
.widget-content .data-container .value-tile {
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}
.widget-content .data-container .value-tile .grid-tile__label {
  flex: 2;
}
.widget-content .data-container .value-tile .grid-tile__value {
  flex: 1;
  font-size: 1.1rem;
}
.widget-content .data-container .presence-label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
}
.widget-content .data-container .presence-label--occupied {
  color: #e91e63;
}
.widget-content .data-container .presence-label--free {
  color: #21ba45;
}
/*# sourceMappingURL=src/oapps/widgets/widget-presence-rate.css.map */