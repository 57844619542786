






































































































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .graph-container
    display flex
    flex 1
    align-items flex-start
    padding $space-2
  .no-data-text
    display flex
    justify-content center
    align-items center
    width 100%
    height 100%
    font-weight 900
    font-size 4rem
  .rt-posts
    width 35%
    height 100%
    .post-list
      display flex
      flex-direction column
      padding 0
      width 100%
      height 100%
      border unset
      .post
        flex-grow 1
        padding $space-1
        border-top-right-radius $border-radius-smooth
        border-bottom-right-radius $border-radius-smooth
        cursor pointer
        &:last-item
          margin-bottom 0
        .post-title
          text-align right
          font-weight 300
          font-size $fs-h3
        .post-value
          text-align right
          font-weight 900
          font-size 1.6rem
          .unit
            font-weight 300
            font-size $fs-h2
        &--selected
          background-color $dash-light
          .post-title
            font-weight 700
  .graph
    display flex
    align-items center
    margin-right 0
    padding $space-1
    width 65%
    height 100%
    border-top-left-radius $border-radius-smooth
    border-bottom-left-radius $border-radius-smooth
    background-color $dash-light
    .line-graph
      width 100%
