
































































































































@import '~variables'

.widget-modal >>> .modal-content
  display flex
  flex-direction column
  width 600px
  height 700px
  .modal__header
    display flex
    align-items center
    margin $space-3 0
    padding 0 $space-3
    .modal-title
      margin 0
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1

.q-stepper
  width 100%
  height 100%
  box-shadow unset
  .q-stepper-step
    flex 0
    &--current
      flex 5
  .q-stepper-nav
    justify-content flex-end
    height 10%
  & >>> .q-stepper-step-content
    height 100%
  & >>> .q-stepper-header
    background-color black
    box-shadow unset
  & >>> .q-stepper-step-inner
    padding $space-3 !important
    height 100%
  & >>> .q-stepper-dot span
    color black
    font-size $fs-h2
  & >>> .q-stepper-dot span .q-icon // step icon color
    color black
  & >>> .q-stepper-tab.step-waiting .q-stepper-dot // step number dot
    color white
  & >>> .q-stepper-tab.step-active // step label - waiting status
    color $dark-pink
  & >>> .q-stepper-tab.step-waiting // step label - active status
    color white

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      margin $space-2 0
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black

.button-add-widget
  text-transform uppercase

.back-button
  margin-right $space-1

.create-button
  margin-left auto
  width fit-content
  font-size $fs-h1
