


































































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 3rem
    .unit
      margin-left $space-little
      font-weight 300
