























































































































@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .threshold-values
      display flex
      flex 1
      flex-direction column
      .value-threshold, .percent-threshold
        text-align right
    .value, .no-data-text
      font-weight 900
      font-size 3rem
    .value
      display flex
      justify-content flex-end
      &--orange-color
        color $warning
      &--red-color
        color $negative
    .unit
      margin-left $space-little
      font-weight 300
    .alert-percentage-loading
      margin 0 -16px
      width 30%
      transform rotate(-90deg)
  .description
    display flex
    padding $space-2
    .live
      display flex
      align-items center
      .live-dot
        display block
        margin-right $space-little
        width 10px
        height @width
        border-radius 50%
        background-color $negative
      .live-label
        color $grey
        letter-spacing 1.5px
    .time
      margin-left auto
      font-weight 300
      font-size $fs-body
