@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-content .main {
  display: flex;
  height: 70%;
}
.widget-content .main .no-data-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 900;
  font-size: 3rem;
}
.widget-content .main .data-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.widget-content .main .data-container .current-value-container {
  text-align: center;
}
.widget-content .main .data-container .current-value-container--value {
  font-weight: 700;
  font-size: 1.6rem;
}
.widget-content .main .data-container .goal-value-container {
  text-align: center;
}
.widget-content .main .data-container .goal-value-container--value {
  font-weight: 700;
  font-size: 1.6rem;
}
.widget-content .main .data-container .donut-container {
  position: relative;
  width: 40%;
  height: 100%;
}
.widget-content .main .data-container .donut-container .donut-percent-value {
  position: absolute;
  bottom: 20%;
  left: 50%;
  z-index: -1;
  font-weight: 700;
  font-size: 2rem;
  transform: translateX(-50%);
}
.widget-content .main .data-container .donut-chart {
  margin: auto;
  width: 60%;
  height: 100%;
}
/*# sourceMappingURL=src/oapps/widgets/widget-sum-gauge-chart.css.map */