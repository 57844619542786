@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-modal >>> .modal-content {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 700px;
}
.widget-modal >>> .modal-content .modal__header {
  display: flex;
  align-items: center;
  margin: 32px 0;
  padding: 0 32px;
}
.widget-modal >>> .modal-content .modal__header .modal-title {
  margin: 0;
}
.widget-modal >>> .modal-content .modal__header .close-button {
  margin-left: auto;
  cursor: pointer;
}
.widget-modal >>> .modal-content .modal__content {
  flex: 1;
}
.q-stepper {
  width: 100%;
  height: 100%;
  box-shadow: unset;
}
.q-stepper .q-stepper-step {
  flex: 0;
}
.q-stepper .q-stepper-step--current {
  flex: 5;
}
.q-stepper .q-stepper-nav {
  justify-content: flex-end;
  height: 10%;
}
.q-stepper >>> .q-stepper-step-content {
  height: 100%;
}
.q-stepper >>> .q-stepper-header {
  background-color: #000;
  box-shadow: unset;
}
.q-stepper >>> .q-stepper-step-inner {
  padding: 32px !important;
  height: 100%;
}
.q-stepper >>> .q-stepper-dot span {
  color: #000;
  font-size: 1.3rem;
}
.q-stepper >>> .q-stepper-dot span .q-icon {
  color: #000;
}
.q-stepper >>> .q-stepper-tab.step-waiting .q-stepper-dot {
  color: #fff;
}
.q-stepper >>> .q-stepper-tab.step-active {
  color: #f3afb3;
}
.q-stepper >>> .q-stepper-tab.step-waiting {
  color: #fff;
}
.creation-step {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 90%;
}
.creation-step .creation-step__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
}
.creation-step .creation-step__header .subtitle {
  margin-bottom: 16px;
  font-size: 1.3rem;
}
.creation-step__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.creation-step__content .space-selector {
  margin: 16px 0;
  font-size: 1.3rem;
}
.creation-step__content .space-selector >>> .vue-treeselect__control {
  border-color: #000;
}
.button-add-widget {
  text-transform: uppercase;
}
.back-button {
  margin-right: 8px;
}
.create-button {
  margin-left: auto;
  width: fit-content;
  font-size: 1.6rem;
}
/*# sourceMappingURL=src/app/pages/dashboard/components/widget-creation.css.map */