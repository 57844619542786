

























































































































@import '~variables'

.grid-tile
  padding $space-1 $space-2

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 1.6rem
    .unit
      margin-left $space-little
      font-weight 300
  .data-container
    display flex
    flex-direction column
    align-items center
    .grid-tile__value
      width 100%
      text-align center
    .live-tile, .value-tile
      width 100%
    .value-tile
      flex-direction row
      align-items center
      padding-top $space-1
      border-top $grid-border
      border-bottom $grid-border
      .grid-tile__label
        flex 2
      .grid-tile__value
        flex 1
        font-size $fs-h3
    .presence-label
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
      &--occupied
        color $negative
      &--free
        color $positive
