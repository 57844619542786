







































































































































































































































































































@import '~variables'

$dashboard-title-fs = 2.3rem

.dashboard
  overflow-y auto
  height calc(100vh - 50px)
  .dashboard__header
    display flex
    align-items center
    justify-content space-between
    align-content center
    background-color $dash-light
    flex-wrap wrap
    padding $space-2
    .dashboard__name-input
      font-size $fs-h2
    .dashboard__name
      position relative
      font-size $dashboard-title-fs
      &:after
        position absolute
        bottom -8px
        width 100%
        content ''
    .dashboard-menu
      display flex
      align-items center
      position relative
      cursor pointer
      user-select none
      .dashboard-menu__title
        font-size $fs-h2
      .dashboard-menu-list
        position absolute
        top 150%
        right 0
        z-index 10
        padding 0
        width 130px
        border-radius $border-radius-smooth
        background-color white
        .q-list
          padding 0
          border unset
          .q-item
            font-weight 400
            &:hover
              background-color $grey
            &:after
              left 0 // make the separator line full width
          .item-delete
            color $negative
  .vue-grid-layout >>> .vue-grid-placeholder
    border-radius $border-radius
    background-color black
  .widget-board
    padding $space-2

.layout-item
  z-index 1
  border-radius $border-radius
  background-color white
  box-shadow $shadow-elevation-1
  &--menu-open
    z-index 2

.description
  display flex
  flex-direction row
  flex-wrap wrap
  align-items center
  justify-content center
  .date
    padding $space-1
    display flex
    flex-wrap wrap
    font-weight 300
    .date-from, .date-to
      flex 1
    .date-from
      text-align right
    .date-to
      white-space nowrap
    .date-label
      margin-right $space-little
      margin-left $space-1
      color $grey
      text-transform uppercase
.bloc
  display flex
  gap 5px
