@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-template {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.widget-template .widget__header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f3f1ef;
}
.widget-template .widget__header .widget-title {
  margin: 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1;
}
.widget-template .widget__header .widget-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.widget-template .widget__header .widget-actions .menu-container {
  margin-left: 8px;
}
.widget-template .widget__header .widget-actions .widget-handle {
  position: relative;
  margin-right: -5px;
  cursor: pointer;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu {
  position: absolute;
  top: 100%;
  left: 100%;
  padding: 0;
  border-radius: 4px;
  background-color: #fff;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu .q-list {
  padding: 0;
  border: unset;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu .q-list .q-item {
  font-weight: 400;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu .q-list .q-item:hover {
  background-color: #b4b2a9;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu .q-list .q-item:after {
  left: 0;
}
.widget-template .widget__header .widget-actions .widget-handle .widget-menu .q-list .menu-item--delete {
  color: #e91e63;
}
/*# sourceMappingURL=src/oapps/widgets/widget-base.css.map */